
import {computed, defineComponent} from "vue";
export default defineComponent({
	name: "form-input",
	props: {
		id: {
			type: String,
			default: '',
		},
		fieldName: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		autocomplete: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
        customClass: {
            types: String,
            default: '',
        }
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		let formInputField = computed({
			get: () => {
				return props.modelValue
			},
			set: (value) => {
				emit('update:modelValue', value)
			}
		})

		return {
			formInputField,
		}
	}
});
