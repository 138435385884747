import {GetterTree} from "vuex";
import {ApplicationState} from "@/store/application/types";
import {RootState} from "@/store/types/types";

export const getters: GetterTree<ApplicationState, RootState> = {
    getOpenLoginModal(state): boolean {
        return state.openLoginModal
    },
    getOpenRegisterModal(state): boolean {
        return state.openRegisterModal
    }
}