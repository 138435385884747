import {Module} from "vuex";
import {RootState} from "@/store/types/types";
import {ApplicationState} from "@/store/application/types";
import {getters} from "@/store/application/getters";
import {mutations} from "@/store/application/mutations";
import {actions} from "@/store/application/actions";



const state: ApplicationState = {
    openLoginModal: false,
    openRegisterModal: false,
    path: '',
}

export const application: Module<ApplicationState, RootState> = {
    state,
    getters,
    mutations,
    actions,
}