import { createApp } from 'vue'
import App from './App.vue'
import './assets/index.css'
import store from './store/index'
import i18n from './i18n.config'

const app = createApp(App)

console.log('STORE ====>', store)

app.use(i18n)
app.use(store)
app.mount('#app');
