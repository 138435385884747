import {GetterTree} from "vuex";
import {UserState} from "@/store/user/types";
import {RootState} from "@/store/types/types";

export const getters: GetterTree<UserState, RootState> = {
    getBalance(state): string | number {
        return state.balance
    },
    getSbToken(state): string {
        return state.sbToken
    },
}