import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-input-wrapper" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "type", "autocomplete", "placeholder", "value", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "sr-only"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      name: _ctx.fieldName,
      type: _ctx.type,
      autocomplete: _ctx.autocomplete,
      class: _normalizeClass(["appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm", _ctx.customClass]),
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInputField) = $event)),
      required: _ctx.required
    }, null, 10, _hoisted_3), [
      [_vModelDynamic, _ctx.formInputField]
    ])
  ]))
}