import {MutationTree} from "vuex";
import {UserState} from "@/store/user/types";
import userMutations from './user-mutations';

export const mutations: MutationTree<UserState> = {
    [userMutations.SET_BALANCE](state, payload: string) {
        state.balance = payload
    },
    [userMutations.SET_SB_TOKEN](state, payload: string) {
        state.sbToken = payload
    },
    [userMutations.IS_LOGGED](state, payload: boolean) {
        state.isLogged = payload
    },
    [userMutations.SET_JWT_TOKEN](state, payload: string) {
        state.jwt = payload
    },
    [userMutations.SET_SELECTED_LANGUAGE](state, payload: string) {
        state.selectedLanguage = payload
    },
}
