import Vuex, { StoreOptions } from 'vuex'
import {RootState} from "@/store/types/types";
import {user} from "@/store/user";
import {application} from "@/store/application";

const store: StoreOptions<RootState> = {
    state: {
        message: 'Root State',
    },
    modules: {
        user,
        application,
    }
}


export default new Vuex.Store<RootState>(store)

