const sv = {
	header: {
		home: 'Hem',
		sports: 'Sport',
		live_betting: 'Live-spel',
		login: 'Logga in',
		register: 'Registrera',
		balance: 'Saldo:',
	},
	profile_dropdown: {
		my_bets: 'Mina spel',
		my_sportsbook: 'Mitt spelbolag',
		log_out: 'Logga ut',
	},
	login: {
		title: 'Logga in på ditt konto',
		username: 'Användarnamn',
		password: 'Lösenord',
		sign_in: 'Logga in',
		error: {
			wrong_signatures: 'Fel kombination av användarnamn och lösenord!',
			something_went_wrong: 'Något gick fel. Försök igen senare.',
		},
		remember_me: 'Kom ihåg mig',
		forgot_password: 'Har du glömt ditt lösenord?',
	},
	register: {
		title: 'Skapa ett konto',
		email: 'E-post',
		username: 'Användarnamn',
		first_name: 'Förnamn',
		last_name: 'Efternamn',
		password: 'Lösenord',
		sign_up: 'Registrera',
		select_currency: 'Välj valuta',
		select_language: 'Välj språk',
		select_odds_format: 'Välj oddsformat',
	},
	footer: {
		all_rights_reserved: 'Alla rättigheter förbehållna.',
	}
}
export default sv
