
import { defineComponent, ref, watch } from "vue"
import { Language, SiteConfig } from "@/store/types/types";
import { siteConfig } from "@/composables/helpers";
import { useStore } from "vuex";
import i18n from "@/i18n.config";

export default defineComponent({
	name: "LanguageDropdown",
	setup() {
		const store = useStore()
		const isDropdownOpen = ref(false);
		const selectedLanguage = ref(localStorage.getItem("selectedLanguage") || 'EN');
		const languagesList = siteConfig().languagesList ?? [] as SiteConfig['languagesList'];

		function toggleLanguagesDropdown(){
			isDropdownOpen.value = !isDropdownOpen.value;
		}

		function updateLanguageSettings(language: Language) {
			const frameContent = document.getElementById('sportsbookcontainer') as HTMLIFrameElement | null;
			if (frameContent !== null) {
				frameContent.src = `${siteConfig().sportsBookUrl}${language.path}`;
			}
			selectedLanguage.value = language.path.slice(1).toUpperCase();
			store.commit('SET_SELECTED_LANGUAGE', language.path.slice(1));
			localStorage.setItem("selectedLanguage", language.path.slice(1));
			i18n.global.locale = language.path.slice(1);
		}

		function handleLanguageClick(language: Language) {
			isDropdownOpen.value = false;
			updateLanguageSettings(language);
		}

		watch(() => store.state.user.selectedLanguage, (newLanguage) => {
			if (newLanguage) {
				const languageToUpdate = languagesList.find(lang => lang.path.slice(1) === newLanguage);
				if (languageToUpdate) {
					selectedLanguage.value = languageToUpdate.path.slice(1).toUpperCase();
					updateLanguageSettings(languageToUpdate);
				}
			}
		}, { immediate: true });

		return {
			languagesList: siteConfig().languagesList,
			toggleLanguagesDropdown,
			handleLanguageClick,
			isDropdownOpen,
			selectedLanguage,
		}
	}
})
