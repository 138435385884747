import apiClient from "./apiClient";
class dataService {
    // eslint-disable-next-line
    getBalance(params: object): Promise<any> {
        return apiClient({
            method: 'post',
            url: '/balance',
            data: params,
            //Add other options if need
        });
    }
    // eslint-disable-next-line
    loginUser(params: object): Promise<any> {
        return apiClient({
            method: 'post',
            url: '/login',
            data: params,
        })
    }
    // eslint-disable-next-line
    logoutUser(): Promise<any> {
        return apiClient({
            method: 'post',
            url: '/logout',
        })
    }
    // eslint-disable-next-line
    registerUser(params: object): Promise<any> {
        return apiClient({
            method: 'post',
            url: '/register',
            data: params,
        })
    }
}
export default new dataService();