
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {LockClosedIcon} from '@heroicons/vue/solid'
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {defineComponent} from "vue";
import FormInput from "@/components/FormInput.vue";
import userActions from '../store/user/user-actions'
import {XIcon} from "@heroicons/vue/outline";

export default defineComponent({
    name: "login-modal",
    components: {
        XIcon,
        FormInput,
        Dialog,
        DialogPanel,
        TransitionRoot,
        TransitionChild,
        LockClosedIcon,
    },
    setup() {
        const store = useStore();
        const username = ref('');
        const password = ref('');
        const error = ref('');

        function closeLoginModal() {
            store.commit('OPEN_LOGIN_MODAL', false)
            clearLoginData()
            error.value = '';
        }

        function onSubmitLogin(e: any) {
            e.preventDefault();
            error.value = '';
            store.dispatch(userActions.LOGIN_USER, {
                username: username.value,
                password: password.value,
            }).then((response: string) => {
                error.value = response;
            })
            closeLoginModal()
        }
        function errorValue(){
            if(error.value === 'Wrong username and password combination!'){
                return '1'
            }else if (error.value === 'Something went wrong. Please try again later.'){
                return '2'
            }else {
                return ''
            }
        }

        function clearLoginData() {
            username.value = ''
            password.value = ''
        }


        return {
            isLoginModalOpen: computed(() => store.state.application.openLoginModal),
            closeLoginModal,
            username,
            password,
            onSubmitLogin,
            clearLoginData,
            error,
            errorValue,
        }
    }
});
