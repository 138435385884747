import {ActionTree, Commit} from "vuex";
import {UserState} from "@/store/user/types";
import {RootState} from "@/store/types/types";
import userActions from './user-actions';
import dataService from "@/services/dataService";
import userMutations from './user-mutations';

export const actions: ActionTree<UserState, RootState> = {
    [userActions.GET_BALANCE]({commit}: {commit: Commit}, params) {
        dataService.getBalance(params).then((response) => {
            if(response.data.error) {
                console.log(response.data.error);
                commit(userMutations.SET_SB_TOKEN, '');
                commit(userMutations.SET_JWT_TOKEN, params.jwt);
            }
            commit(userMutations.SET_BALANCE, response.data.balance)
        })
    },
    [userActions.LOGIN_USER]({commit}: {commit: Commit}, params) {
        return new Promise((resolve) => {
            dataService.loginUser(params)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 'success') {
                        commit(userMutations.SET_SB_TOKEN, data.token);
                        commit(userMutations.SET_JWT_TOKEN, data.jwt);
                        commit(userMutations.IS_LOGGED, true);
                    } else if (data.error) {
                        resolve(data.error);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    resolve('Something went wrong. Please try again later.');
            })
        });
    },
    [userActions.LOGOUT_USER]({commit}: {commit: Commit}) {
        dataService.logoutUser().then((response) => {
            const data = response.data;
            if(data.status === 'success') {
                commit(userMutations.SET_SB_TOKEN, '');
                commit(userMutations.SET_JWT_TOKEN, '');
                commit(userMutations.SET_BALANCE, '');
                commit(userMutations.IS_LOGGED, false);
            }

            if (response.error) {
                console.log(response.error)
            }
        })
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [userActions.REGISTER_USER]({commit}: {commit: Commit}, params) {
        return new Promise((resolve, reject) => {
            dataService.registerUser(params).then(
                response => {
                    resolve(response.data);
                },
                error => {
                    reject(error)
                }
            )
        })
    },
}
